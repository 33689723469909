import { footerSectionLinks } from "@components/utils/Utils";
import {
  IFooterResponse,
  ITopNavigationResponse,
} from "@customTypes/commonResponses";
import { IUSPComponentResponse } from "@customTypes/componentResponses";
import { PAGE_TYPES, PageTypes } from "@customTypes/index";
import FooterRibbonCTA from "@modules/footer/FooterRibbonCTA";
import dynamic from "next/dynamic";
import { useMemo } from "react";
import FooterLayout from "./FooterLayout";

const UniqueSellingPoints = dynamic(
  async () => await import("../UniqueSellingPoints"),
  {
    ssr: true,
  }
);
const SocialIcons = dynamic(async () => await import("../SocialIcons"), {
  ssr: true,
});

interface IFooterProps {
  topNavigation: ITopNavigationResponse;
  footer: IFooterResponse;
  usp: IUSPComponentResponse[];
  pageType: PageTypes;
}

const FOOTER_TABS_COUNT = 4;

const Footer = ({
  topNavigation,
  footer,
  usp,
  pageType,
}: IFooterProps): JSX.Element => {
  const footerSections = useMemo(() => {
    if (!footer) return null;

    const footerTabs = [];

    for (let index = 1; index <= FOOTER_TABS_COUNT; index++) {
      if (footer[`tab${index}`]?.length > 0) {
        footerTabs.push(footerSectionLinks(footer[`tab${index}`]));
      }
    }

    return footerTabs;
  }, [footer]);

  if (!footer || !usp || !footerSections) return <div />;

  return (
    <>
      {PAGE_TYPES.Home !== pageType && <UniqueSellingPoints usp={usp} />}
      <FooterRibbonCTA
        text={footer?.Footer_Ribbon}
        phoneAction={`tel:${topNavigation?.Phone_Action}`}
        phoneNumber={topNavigation?.Phone_Visible}
      />
      <footer
        className={`relative bottom-0 z-20 w-full bg-blue-5 pt-[2rem] desktop:pt-0`}
      >
        <div className="mx-auto desktop:max-w-[1536px] desktop:px-[12px]">
          <FooterLayout footer={footer} footerSections={footerSections} />
          <SocialIcons />
        </div>
      </footer>
    </>
  );
};

export default Footer;
