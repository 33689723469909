import ConditionalWrapper from "@components/common/ConditionalWrapper";
import { slashBeforeUrlAdder } from "@components/utils/urlHelpers";
import { IFooterTabComponentResponse } from "@customTypes/componentResponses";
import useAppContext from "@hooks/useAppContext";
import Link from "next/link";

export interface IFooterSection {
  section: IFooterTabComponentResponse;
  links: IFooterTabComponentResponse[];
}

const FooterSection = ({
  section,
}: {
  section?: IFooterSection;
}): JSX.Element => {
  const { isRealMobileDevice } = useAppContext();
  return (
    <div className="w-full">
      <div className="mb-8">
        {section?.section && (
          <span className="mb-2 cursor-default text-p text-darkgray desktop:mb-3">
            {section?.section.Link ?? "\xa0"}
          </span>
        )}
        <ul className="text-sm text-lightblue">
          {section?.links.map((link) => (
            <li
              key={link.id}
              tabIndex={0}
              className={`mb-2 ${
                link.URL
                  ? isRealMobileDevice
                    ? "active:text-darkblue active:underline"
                    : "hover:text-darkblue hover:underline"
                  : "cursor-default"
              } text-p14 font-medium`}
            >
              <ConditionalWrapper
                condition={!!link.URL}
                wrapper={(children) => (
                  <Link href={slashBeforeUrlAdder(link.URL)}>{children}</Link>
                )}
              >
                <span role="link">{link.Link}</span>
              </ConditionalWrapper>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FooterSection;
